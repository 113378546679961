import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    updateSchool(state, action) {
      const updates = action.payload;
      Object.keys(updates).forEach(key => {
        // Handle nested objects and arrays
        if (typeof updates[key] === 'object' && !Array.isArray(updates[key])) {
          state[key] = { ...state[key], ...updates[key] };
        } else if (Array.isArray(updates[key])) {
          state[key] = [...updates[key]];
        } else {
          state[key] = updates[key];
        }
      });
    },
    resetSchoolState(state, action) {
      return initialState;
    }
  },
});

export const {
 
  updateSchool,
  resetSchoolState
} = schoolSlice.actions;

export default schoolSlice.reducer;
