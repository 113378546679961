// reducers.js
import { combineReducers } from '@reduxjs/toolkit';
import schoolReducer from './slices/schoolSlice';
// import 

const rootReducer = combineReducers({
  school: schoolReducer,
  // Add other reducers here
});

export default rootReducer;
