'use client'
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { loadState, saveState } from './localStorage';

const preloadedState = loadState()

export const store = configureStore({
   reducer: rootReducer,
   preloadedState,
});


store.subscribe(() => {
    console.log("Store saved")
    saveState(store.getState())
})


export default store;
